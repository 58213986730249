<script setup lang="ts">
import Header from "~~/components/layoutComponents/header/Header.vue";
import {useI18n} from "vue-i18n";
import {useSeoMeta} from "@unhead/vue";
const i18n = useI18n()
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true
})


const title = computed(() => i18n.t('home.whatsTheWeatherLikelyToBe'))
useSeoMeta({
  description: () => i18n.t('home.usingOver550000WeatherObservationsFrom300UsWeather'),
  ogType: 'website',
  ogTitle: () => i18n.t('home.whatsTheWeatherLikelyToBe'),
  ogDescription: () => i18n.t('home.usingOver550000WeatherObservationsFrom300UsWeather'),
  ogImage: '/images/climatespy.svg'
})


</script>
<script lang="ts">
export default {
  name: "HomePageLayout",
};
</script>
<template>
  <div>
    <Html
      :lang="i18n.locale.value"
    >
      <Head>
        <Title>{{ title }}</Title>
        <link
          rel="preconnect"
          href="https://data.climatespy.com/"
        >
        <link
          rel="preconnect"
          href="https://data.climatespy.com/"
        >
        <link
          rel="preconnect"
          href="https://o1196301.ingest.sentry.io"
        >
        >
        <template
          v-for="link in head.link"
          :key="link.id"
        >
          <Link
            :id="link.id"
            :rel="link.rel"
            :href="`https://climatespy.com/${link.href}`"
            :hreflang="link.hreflang"
          />
        </template>
        <template
          v-for="meta in head.meta"
          :key="meta.id"
        >
          <Meta
            :id="meta.id"
            :property="meta.property"
            :content="meta.content"
          />
        </template>
      </Head>
      <body>
        <v-app>
          <Header />
          <v-main class="mt-10">
            <v-container :fluid="true">
              <slot />
            </v-container>
          </v-main>
        </v-app>
      </body>
    </Html>
  </div>
</template>
